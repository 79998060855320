.easteregg-background {
    width: 100%;
    height: 100%;
    object-fit: fit;
    position: absolute;
    top: 0;
    left:0;
}

.easteregg-container {
    z-index: 1000;
    position: fixed;
    // top: $lh * 0.5;
    // left: $lh * 0.5;
    width: calc(100vw - 1.38em);
    height: calc(100vh - 1.38em);
    // border-radius: $lh / 4;
    opacity: 0.5em;
    // padding: $lh;
    // padding-top: $lh / 2;
    background: rgba(0,0,0,0.8);
    color: var(--tc);
    // border: 1px solid var(--tc);
    
    overflow: auto;
}

.easteregg-text {
    width: 30%;
    min-height: 4rem;
    padding: $lh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-33%);
    color: black;
}

.easteregg-link {
    display: block;
    text-decoration: none;
    color: inherit;
    margin-bottom: $lh;
    margin-top: $lh;
}

.easteregg-link {
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    padding: $lh * 0.61;
    color: inherit;
    text-decoration: none;
    display: inline-block;
    // background: linear-gradient(90deg, var(--dark) 24%, var(--sc) 43%, var(--sc) 63%, var(--sc) 80%, var(--dark) 100%);
    // background-size: 200% 100%;
    // animation: highlight 9s linear infinite;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 8em;
    // width: calc(100vw - 3.8em); 
    // margin-left: 0.15em;
    // margin-right: 0.15em;    
}

.easteregg-link:hover {
    background: linear-gradient(90deg, var(--dark) 24%, var(--sc) 43%, var(--sc) 63%, var(--sc) 80%, var(--dark) 100%);
    background-size: 200% 100%;
    animation: highlight 9s linear infinite;
}

.easteregg-title {
    font-size: inherit;
    font-weight: 500;
    font-size: 2em;
}

.easteregg-author {
    margin-top: $lh;
}