@import "variables.scss";
@import "default.scss";
@import "header.scss";
@import "menu.scss";
@import "instructions.scss";
@import "auth.scss";
@import "splashscreen.scss";
@import "profile.scss";
@import "nav.scss";
@import "about.scss";
@import "details.scss";
@import "livestream.scss";
@import "installation.scss";
@import "languageSwitch.scss";
@import "artwork.scss";
@import "footer.scss";
@import "eastereggs.scss";
@import "livevideo.scss";


#root {
    width: 100%;
    height: 100%;
}

.renderCanvas {
    position: fixed;
    top: 0;
    left: 0; 
    width: 100%;
    height: 100%;
    touch-action: none;
    z-index: 10;
}

.main {
    position: absolute;
    top: 0;
    left: 0;
    color: $pc;
}

@keyframes spin { 
    100% { 
    transform: rotate(360deg); 
     
    } 
}
.main-container {
    width: 100%;
    height: 100%;  
}
canvas {
    width: 100%;
    height: 100%;
}

.splashScreen-loading-pos {
    z-index: 100;
    width: 8vw;
    height: 8vw;
    position: absolute;
    top: calc(50% - 5vw);
    left: 0;
    transform: translate(0em,-50%);
}


.splashScreen-contributors {
    z-index: 100;
    position: relative;
    color: white;
    font-size: $big;
    width: 100%;
    text-align: center;
    margin-top: $lh;
}





.artwork-videoplayer {
    width: 100%;
    height: 100%;
    
    iframe {
        background: black;
        width: 100%;
        height: 100%;
    }
}




.video-container {
    width: 100%;
    position: relative;
    background: white;
    border-radius: $radius;

    .video-container-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $radius;
        overflow: hidden;
    }
}

.video-container-after {
    content: '';
    width: 100%;
    display: block;
    height: 0;
    width: 100%;
}



.splashScreen-animation-enter {
    opacity: 0;
    transition: all 1s ease;
}

.splashScreen-animation-enter-active {
    opacity: 1;
    transition: all 1s ease;
}
.splashScreen-animation-exit {
    opacity: 1;
}
.splashScreen-animation-exit-active {
    opacity: 0;
    transition: opacity 1800ms;
}

.mt-1 {
    margin-top: $lh;
}

.artwork-caption {
    margin-top: $lh * 2;
    opacity: 1;
}

canvas {
    z-index: 100;
}


textarea {
    font-size: inherit;
    border: none;
    resize: none;
    height: $lh;
}

.small {
 font-size: 0.75em;
}


.restartVideo {
    z-index: 1000;
    position: absolute;
    margin: $lh * 0.25;
    left: 0;
    top: 15em;
    // top: $lh * 0.25;
    // left: $lh * 0.25;
    background: yellow;
    padding: $lh * 0.5;
    border-radius: 0.5em;
    width: 7em;
}

  

  
  