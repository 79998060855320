.chat-status {
    margin-top: $lh * 0.5;
    background: rgba(0, 0, 0, 0.5);
    border-radius: $radius;
    position: absolute;
    bottom: $lh * 0.25;
    padding: $lh * 0.25;
    padding-left: $lh * 0.5;
    padding-right: $lh * 0.5;
    left: $lh * 3.5 ;
    z-index: 1000;
    position: fixed;
    font-size: 1.25rem;

    a {
        color: white;
        margin-right: $lh * 0.5;
        text-decoration: none;
        opacity: 0.5;
        padding-bottom: 0.07em;
        cursor: pointer;
    }

    @media only screen and (max-width: 750px) {
        bottom: $lh * 1.8;
        left: $lh * 0.25;
    }
}

.chat-status-profile {
    display: inline-block;
    img {
        margin-left: 1em;
        width: 1.4em;
        height: 1.4em;
        border-radius: 5em;
        vertical-align: middle;
    }
}

// .chat-version {
//     font-size: 0.5em;
//     margin-bottom: 0.5em;
// }

.chat-title {
    padding: $lh * 0.25;
    margin-left: $lh * 0.25;
    z-index: 500;
    display: inline-block;
    height: auto;
    min-width: 10em;
    vertical-align: top;
    border-radius: $radius * 0.25;
    overflow: hidden;
    max-width: 11em;
    margin-bottom: $lh * 0.125;
}

