.details {
    z-index: 10000;
    backdrop-filter: blur(20px);
    top: 0;
    left: 0;
    position: fixed;
    // filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.8));
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    background: rgba(0,20,0,0.9);
    width: 100vw;
    height: 100vh;
    font-size: $medium;
}


.container {
    padding: $lh * 2;
    // padding-top: $lh / 2;
  //  background: green;
    width: 100vw;
    height: 100vh;
    overflow: scroll;
  }
  
  .slick-slide img {
      width: 100%;
    margin: auto;
    padding: $lh * 1;
    // padding-bottom: 0;
    max-height: calc(100vh - 12em);
    object-fit: contain;
  }

  .gallery-captions {
    margin-top: $lh * 2;
    // text-align: center;
    padding-left: $lh;
  }

  
.artwork-container-close {
  padding-bottom: $lh * 0.25;
  // font-family: 'youth';
  letter-spacing: .05em; 
  font-size: $medium;
  text-align: right; 

  z-index: 900;
  position: fixed;
  top: 0;
  right: 0;
  background: $sc;
  margin: $lh * 0.25;
  padding: 0.7em;
  padding: 0.35em;
  color: white;
  text-decoration: none;
  border-radius: 0.175em;
  text-align: center;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.8));
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);

}

.slick-dots li button {
  font-size: 2em;
  line-height: 0;
  display: block;
  width: 4em !important;
  height: 4em !important;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 18px !important;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: $sc !important;
}

.text-captions {
  text-align: left;
  margin-top: $lh;
  // padding-right: $lh * 2;
}
