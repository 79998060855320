.support-button {
    z-index: 900;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #571f91;
    margin: $lh * 0.5;
    padding: $lh * 0.5;
    padding-bottom: $lh * 0.25;
    color: white;
    text-decoration: none;
    border-radius: $lh * 0.125;
    text-align: center;
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.8));
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);

    img {
        margin-bottom: $lh * 0.25;
        width: 4em;
        display: block;
    }
}

.menu-button {
    z-index: 900;
    position: fixed;
    top: 0;
    left: 0;
    background: $tc;
    margin: $lh * 0.25;
    padding: $lh * 0.5;
    padding-top: $lh * 0.25;
    color: black;
    text-decoration: none;
    border-radius: $radius * 0.5;
    text-align: center;
    width: 5em;
    height: 5em;
    // filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.8));
    // box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);
    cursor: pointer;
    background: var(--tc);
    color: var(--sc);
    transition: 500ms all;
}

.menu-button:hover {
    transform: scale(0.9);
    transition: 500ms all;
}

.overlay-button {
    z-index: 100;
    position: fixed;
    top: $lh * 7;
    left: 0;
    background: rgba(255,255,255,0.4);
    margin: $lh * 0.25;
    padding: $lh * 0.5;
    padding-top: $lh * 0.5;
    color: black;
    text-decoration: none;
    border-radius: $radius;
    line-height: 1;
    text-align: left;
    width: 10em;
    // height: 3.4em;
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.8));
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);
    cursor: pointer;

    a {
        display: block;
        margin-bottom: $lh * 0.5;
    }
}

.menu-button-close {
    cursor: pointer;
    z-index: 950;
    position: absolute;
    top: 0;
    background: none;
    border: 1px white solid;
    margin: $lh * 0.5;
    padding: $lh * 0.5;
    padding-bottom: $lh * 0.25;
    color: white;
    text-decoration: none;
    text-align: center;

    img {
        margin-bottom: $lh * 0.25;
        width: 4em;
        display: block;
    }
}

.menu-button-close-color {
    cursor: pointer;
    z-index: 950;
    position: absolute;
    top: 0;
    background: var(--background2);
    border: 1px solid var(--tc);

    margin: $lh * 0.5;
    padding: $lh * 0.5;
    padding-bottom: $lh * 0.25;
    text-decoration: none;
    text-align: center;

    img {
        margin-bottom: $lh * 0.25;
        width: 4em;
        display: block;
    }
}

.menu-button-close:hover, .menu-button-close-color:hover {
    opacity: 0.5;
    transition: 500ms all;
}


.menu-left {
    left: 0;
}

.menu-right {
    right: 0;
}


.about {
    padding: $lh;
    padding-left: $lh;
    padding-top: $lh * 4;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: $lh;
    overflow: scroll;
    height: 100%;
    background: var(--tc);
    z-index: 900;
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    box-shadow: 0 8px 5px 5px rgba(0,0,0,.5);

    p {
        margin-top: 0;
    }

    @media only screen and (max-width: 750px) {
        width: 100%;
    }
}

.about-col {
    margin-bottom: 15em;

    h1 {
        // font-size: inherit;
        margin-bottom: $lh;
    }

    div {
        margin-bottom: $lh;
    }

    a {
        color: inherit;
    }
}
.logn-text {
    p {
        margin-bottom: $lh * 0.5;
    }
}
// .about-col {
//     p {
//         margin-bottom: $lh / 2;
//     }

// }

.about-main-link {
    background: $tc;
    display: inline-block;
    padding: $lh * 0.25;
    color: black !important;
    margin-bottom: $lh * 0.5;
    border-radius: $radius * 0.5;
    text-decoration: none;
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);
}

.about-names {
    list-style-type:none;
}