.instructions-container {
    position: fixed;
    -webkit-margin-top-collapse: $lh * 2;
    bottom: $lh * 0.5;
    left: $lh * 0.5;
    z-index: 1000;
    padding-bottom: $lh * 0.25;
    font-size: 1em;
    color: white;
    padding: $lh * 0.5;
    // transform: translate(-50%, -50%);
    border-radius: $radius * 0.5;
    background: rgba(0,0,0,0.9);
    background-color: $tc;
    pointer-events: none;
    max-width: 19em;
    width: 100%;
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.3));
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.3);

    span {
        background: white;
        color: black;
        font-size: 0.9em;
        border-radius: 0.1em;
        line-height: 1.4;
        display: inline-block;
        padding: 0.2em;
        padding-bottom: 0.05em;
        padding-top: 0;
        margin-left: 0.15em;
        width: 1.2em;
        text-align: center;
    }
}

.instructions-container-secondary {
    position: fixed;
    -webkit-margin-top-collapse: $lh * 2;
    top: $lh * 0.5;
    right: $lh * 0.5;
    z-index: 1000;
    padding-bottom: $lh * 0.25;
    font-size: 1em;
    color: white;
    padding: $lh * 0.5;
    // transform: translate(-50%, -50%);
    border-radius: $radius * 0.5;
    background: rgba(0,0,0,0.9);
    background-color: $tc;
    pointer-events: none;
    max-width: 19em;
    width: 100%;
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.3));
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.3);

    span {
        background: white;
        color: black;
        font-size: 0.9em;
        border-radius: 0.1em;
        line-height: 1.4;
        display: inline-block;
        padding: 0.2em;
        padding-bottom: 0.05em;
        padding-top: 0;
        margin-left: 0.15em;
        width: 1.2em;
        text-align: center;
    }
}