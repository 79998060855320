.liveVideo {
    // padding: .75vw;
    width: 20vw;
    height: 15vw;
    // width: calc(50vw - 16em);
    box-sizing: border-box;
    position: absolute;
    bottom: $lh * 3 + 0.35em;
    left: 0.35em;
    z-index: 1000;
    // background: var(--pc);

    video {
      display: block;
      box-shadow: 0px 0px 15px 5px rgb(0, 0, 0);
      transform: scale(-1, 1);
      width: 100%;
    }
  }
  
  .lobby {
    text-align: left;
    font-size: 1.25em;
  }
  
  .participants {
    // display:flex;
    // flex-wrap:wrap;
    // width:100%;
  }
  
  .participant {
    box-sizing: border-box;
    position: relative;
    border-radius: 0.5em;
    width: 20vw;
    height: 15vw;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black); 
    display: flex;
    justify-content: center;
  }
  
  .identity {
    position: absolute;
    left: 0;
    bottom: $lh * 0.25;
    color: white;
    padding-left: $lh * 0.25;
    z-index: 10;
    font-size: 0.7rem;
  }
  
  // video {
  //   display: block;
  //   box-shadow: 0px 0px 15px 5px rgb(0, 0, 0);
  //   transform: scale(-1, 1);
  //   width: 100%;
  // }
  
  // button {
  //   background-color: #fe019a;
  //   font-weight: bold;
  //   display: block;
  //   color: white;
  //   border: none;
  //   cursor: pointer;
  //   font-size: 0.7em;
  // }
  
  // button:disabled {
  //   opacity: .4;
  //   cursor:initial;
  // }
  
  #leaveRoom {
    margin-top: 25px;
    margin-left: 0;
  }
  
  // input {
  //   padding-bottom: 5px;
  //   border: none;
  //   outline: none;
  //   border-bottom: 1px solid #555;
  //   margin-bottom: 25px;
  //   width: 200px;
  //   font-size: 1em;
  //   text-align: center;
  //   border-radius: 0;
  // }
  
  // @media (max-width: 1048px) {
  //   .app {
  //     padding: 1vw;
  //   }
  
  //   .participant {
  //     width: 47vw;
  //     margin-top: 1vw;
  //     margin-bottom: 1vw;
  //     margin-left: 2vw;
  //     height: 42vh;
  //   }
  
  //   @media (orientation:landscape) {
  //     .participant {
  //       width: 48vw;
  //       margin-left: 1vw;
  //       height: 96vh;
  //     }
  //   }
  
  // }
  

  .videomute, .videounmute {
    position: absolute;
    top: $lh * 0.25;
    left: $lh * 0.25;
    background: yellow;
    padding: $lh * 0.5;
    border-radius: 0.5em;
    width: 7em;
  }

  .videounmute {
    background: var(--warning);
  }