.splashScreen {
    width: 100%;
    height: 100%;
    background: $tc;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 500;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg,var(--sc) 24%,var(--tc) 43%,var(--tc) 63%,var(--tc) 80%,var(--sc) 100%);
    animation: highlight 3s linear infinite;
    background-size: 200% 100%;
    outline-color: var(--sc);
}

.splashScreen-landing {
    width: 100%;
    height: 100%;
    background: $tc;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 500;
    width: 100%;
    height: 100%;
}


.splashScreen-loading-container {
    position: relative;
    width: 100%;
    height: calc(100% - 4em);
}

.splashScreen-svg {
    fill: white;
    object-fit: fill;
    width: 100%;
    height: 100%;
}

.splashScreen-loading {
    position: relative;
    z-index: 100;
    width: 18vw;
    max-width: 25vh;
    height: 18vw;
    fill: white;
    animation:spin 4s linear infinite;
}

.splashScreen-title {
    width: 15em;
    height: 15em;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
}

.splashScreen-image {
    width: 100%;
    height: 100%;



    img, video {
        // position: absolute;
        // top: 0;
        // left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
}





.index-video-wrapper {
    height: 100%;
    width: 100%;
    background-size: cover;
    background: black;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
}

.text {
    position: absolute;
    top: 0.5em;
    left: 0;
    z-index: 100;
    background: rgba(0,0,0,0.2);
    padding: 2em;
    border-radius: 0.1em;
    backdrop-filter: blur(3px);
    margin: 1em;
    margin-top: 0;
    border-radius: 0.2em;
    border-right: 2px solid white;
    border-left: 2px solid white;

    div {
        margin-bottom: 3em;
    }
}

h1 {
    font-weight: normal;
    margin-top: 0;
}

.background {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.tetem-logo {
    div {
        margin-bottom: 0;
    }

    svg {
        width: 10em; 
        height: 5em;      
    }
}

$t: transparent;

.emphase {
    display: inline-block;
    margin: 0;
    background: rgba(255,255,255,0.3);
    padding: 0.25em;
    border-radius: 0.2em;
}

p {
    margin: 0;
}

.dot {
    font-size: 1.25em;
    color: #b81a6e;
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
	0% {
        transform: scale(0.95);
        opacity: 1;
	}

	50% {
        transform: scale(1);
        opacity: 0.5;
	}

	100% {
        transform: scale(0.95);
        opacit: 1;
	}
}


.firebaseui-idp-password {
    background: $g1 !important;
    color: black !important;
}

.firebaseui-idp-text, .firebaseui-idp-text-long {
    font-family: helvetica !important;
}

// .splashScreen-info {
//     position: absolute;
//     background: none;
//     font-size: 0.65em;
//     padding: $lh / 2;
//     left: 0;
//     bottom: 0;
//     // background: black;
//     margin: $lh / 2;
//     border-radius: $radius / 2;
//     filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.8));
//     box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);
//     opacity: 0.7;

//     a {
//         color: #6F997E;
//     }

//     @media only screen and (max-width: 750px) {
//         display: none;
//     }
// }

.splashScreen-name {
    position: absolute;
    background: none;
    font-size: $big;
    padding: $lh * 0.25;
    left: 4em;
    top: 0;
    color: black;

    img {
        width: 90%;
    }
}

.splashScreen-main-title {
    color: black;
    bottom: 15%;
    left: 50%;
    position: absolute;
    transform: translate(-50%,0);

    background: linear-gradient(90deg,var(--sc) 24%,var(--tc) 43%,var(--tc) 63%,var(--tc) 80%,var(--sc) 100%);
    animation: highlight 2s linear infinite;
    background-size: 200% 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    outline-color: var(--sc);
}


.splashScreen-help {
    color: #e2b698;
    position: absolute;
    bottom: $lh * 0.5 ;
    left: $lh *0.5;
    font-size: 0.7rem;

    @media only screen and (max-width: 750px) {
        bottom: $lh * 0.5;
    }
}

.splashScreen-info{
    a {
        color: var(--sc)
    }
}