:root {
  --lh: 1.38rem;
  --pc: #ff5b00;
  --sc: #98a6c3;
  --tc: #7d8474;
  --warning: #ee4c30;
  --white: white;
  --dark: black;
  --darkhalf: #0000004d;
  --lightgray: #d3d3d3;
  --background: #e1dfc9;
  --background2: #171a22;
  --navcolor: #7d8474;
  --color2: #0f0;
  --color: black;
  --brightblue: #8b9cbd;
  --radius: .2em;
  --mini2: .6rem;
  --small2: .8rem;
}

@font-face {
  font-family: NH;
  src: url("ABCDiatype-Medium.f962fc2c.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  -webkit-hyphens: none;
  hyphens: none;
  -webkit-tap-highlight-color: #fff0;
  outline: none;
}

html, body {
  width: 100%;
  height: 100%;
  background: yelow;
  text-rendering: geometricprecision;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  background: #000;
  margin: 0;
  padding: 0;
  font-family: NH, Helvetica Neue, arial, sans-serif;
  font-size: 19px;
  overflow: hidden;
}

.header {
  color: #fff;
  z-index: 100;
  -webkit-user-select: none;
  user-select: none;
  color: #fff;
  width: 70vw;
  padding: .175em .35em .35em;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

@media only screen and (max-width: 750px) {
  .header {
    width: 100%;
    padding-left: 7.5em;
    font-size: .8em;
    left: 0;
    transform: translate(0);
  }
}

.footer-mute {
  z-index: 900;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  color: var(--tc);
  background: #8b9cbd;
  background: var(--sc);
  border-radius: .02em;
  padding: .35em;
  font-size: 1.25rem;
  display: inline-block;
  position: fixed;
  bottom: .35em;
  left: .35em;
}

.footer-button {
  cursor: pointer;
  color: gray;
  margin-right: .7em;
  display: inline-block;
}

.footer-button:hover {
  text-decoration: underline;
}

.chat-togle {
  z-index: 900;
  color: var(--pc);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  color: var(--tc);
  background: none;
  border-radius: .02em;
  padding-right: .7em;
  font-size: 1.25rem;
  display: inline-block;
  position: fixed;
  bottom: .35em;
  right: .35em;
}

.footer-right {
  color: #fff;
  color: #000;
  opacity: .5;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  border-radius: .00125em;
  margin: .35em;
  padding: 0 .35em .35em;
  font-size: 1.55rem;
  position: fixed;
  bottom: 0;
  left: 0;
}

.footer-left {
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  z-index: 100;
  margin: .35em;
  padding: .35em;
  font-size: 1em;
  position: fixed;
  bottom: .5em;
  left: 0;
}

.header-container {
  position: relative;
}

.flashmessage-container {
  text-align: left;
}

.flashmessage {
  border-radius: .01em;
  margin-top: .35em;
  padding: .7em;
  display: inline-block;
}

.menu-container {
  width: 50%;
  height: 100%;
  z-index: 110;
  color: #fff;
  -webkit-backdrop-filter: brightness(50%) blur(10px);
  backdrop-filter: brightness(50%) blur(10px);
  background: #000;
  border-left: 2px solid #fff;
  padding: 1.4em;
  position: fixed;
  top: 0;
  left: 50%;
  overflow: auto;
}

@media only screen and (max-width: 750px) {
  .menu-container {
    width: 100%;
    left: 0;
  }
}

.babylonUnmuteIcon {
  border-radius: .005em;
  width: 8em !important;
  height: 5.4em !important;
  top: 10% !important;
  left: 1em !important;
}

.menu-animation-enter {
  opacity: 0;
  transition: all .2s;
}

.menu-animation-enter-active, .menu-animation-enter-done {
  opacity: 1;
}

.menu-animation-exit {
  opacity: 1;
  transition: all .2s;
}

.menu-animation-exit-active {
  opacity: 0;
  transition: all .2s;
}

.menu-text {
  font-size: 1.38em;
}

.menu-text-cz {
  padding-left: 2.8em;
  font-size: 1.38em;
}

.youth {
  font-family: youth;
  font-size: 1.1em;
}

.menu-credits-cz {
  padding-left: 2.8em;
}

.menu-credits {
  margin-top: 5.6em;
}

.menu-credits-adela, .menu-status {
  margin-top: 1.4em;
}

.instructions-container {
  -webkit-margin-top-collapse: 2.8em;
  z-index: 1000;
  color: #fff;
  pointer-events: none;
  max-width: 19em;
  width: 100%;
  filter: drop-shadow(0 0 4px #0000004d);
  background: #8b9cbd;
  border-radius: .005em;
  padding: .7em;
  font-size: 1em;
  position: fixed;
  bottom: .7em;
  left: .7em;
  box-shadow: inset 0 0 10px #0000004d;
}

.instructions-container span {
  color: #000;
  width: 1.2em;
  text-align: center;
  background: #fff;
  border-radius: .1em;
  margin-left: .15em;
  padding: 0 .2em .05em;
  font-size: .9em;
  line-height: 1.4;
  display: inline-block;
}

.instructions-container-secondary {
  -webkit-margin-top-collapse: 2.8em;
  z-index: 1000;
  color: #fff;
  pointer-events: none;
  max-width: 19em;
  width: 100%;
  filter: drop-shadow(0 0 4px #0000004d);
  background: #8b9cbd;
  border-radius: .005em;
  padding: .7em;
  font-size: 1em;
  position: fixed;
  top: .7em;
  right: .7em;
  box-shadow: inset 0 0 10px #0000004d;
}

.instructions-container-secondary span {
  color: #000;
  width: 1.2em;
  text-align: center;
  background: #fff;
  border-radius: .1em;
  margin-left: .15em;
  padding: 0 .2em .05em;
  font-size: .9em;
  line-height: 1.4;
  display: inline-block;
}

.auth {
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.auth-title {
  z-index: 900;
  width: 15em;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  text-align: center;
  color: var(--tc);
  background: #8b9cbd;
  border-radius: .01em;
  margin-bottom: 1.4em;
  padding: 1.4em;
  transition: all .5s;
  display: block;
  box-shadow: inset 0 0 10px #00000080;
}

.auth-3d {
  background: linear-gradient(90deg, var(--dark) 24%, var(--sc) 43%, var(--sc) 63%, var(--sc) 80%, var(--dark) 100%);
  height: 10em;
  background-size: 200% 100%;
  animation: highlight 9s linear infinite;
}

.auth-title:hover {
  transition: all .5s;
  transform: scale(.95);
}

@keyframes highlight {
  0% {
    background-position: -20%;
  }

  to {
    background-position: 180%;
  }
}

.splashScreen {
  width: 100%;
  height: 100%;
  z-index: 500;
  width: 100%;
  height: 100%;
  background: #8b9cbd;
  background: linear-gradient(90deg, var(--sc) 24%, var(--tc) 43%, var(--tc) 63%, var(--tc) 80%, var(--sc) 100%);
  outline-color: var(--sc);
  background-size: 200% 100%;
  animation: highlight 3s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
}

.splashScreen-landing {
  width: 100%;
  height: 100%;
  z-index: 500;
  width: 100%;
  height: 100%;
  background: #8b9cbd;
  position: absolute;
  top: 0;
  left: 0;
}

.splashScreen-loading-container {
  width: 100%;
  height: calc(100% - 4em);
  position: relative;
}

.splashScreen-svg {
  fill: #fff;
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.splashScreen-loading {
  z-index: 100;
  width: 18vw;
  max-width: 25vh;
  height: 18vw;
  fill: #fff;
  animation: spin 4s linear infinite;
  position: relative;
}

.splashScreen-title {
  width: 15em;
  height: 15em;
  color: #000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.splashScreen-image {
  width: 100%;
  height: 100%;
}

.splashScreen-image img, .splashScreen-image video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.index-video-wrapper {
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.text {
  z-index: 100;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background: #0003;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-radius: .2em;
  margin: 0 1em 1em;
  padding: 2em;
  position: absolute;
  top: .5em;
  left: 0;
}

.text div {
  margin-bottom: 3em;
}

h1 {
  margin-top: 0;
  font-weight: normal;
}

.background {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tetem-logo div {
  margin-bottom: 0;
}

.tetem-logo svg {
  width: 10em;
  height: 5em;
}

.emphase {
  background: #ffffff4d;
  border-radius: .2em;
  margin: 0;
  padding: .25em;
  display: inline-block;
}

p {
  margin: 0;
}

.dot {
  color: #b81a6e;
  font-size: 1.25em;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(.95);
  }

  50% {
    opacity: .5;
    transform: scale(1);
  }

  100% {
    opacit: 1;
    transform: scale(.95);
  }
}

.firebaseui-idp-password {
  background: linear-gradient(90deg, var(--sc) 0%, black 35%, var(--sc) 100%) !important;
  color: #000 !important;
}

.firebaseui-idp-text, .firebaseui-idp-text-long {
  font-family: helvetica !important;
}

.splashScreen-name {
  color: #000;
  background: none;
  padding: .35em;
  font-size: 1.55rem;
  position: absolute;
  top: 0;
  left: 4em;
}

.splashScreen-name img {
  width: 90%;
}

.splashScreen-main-title {
  color: #000;
  background: linear-gradient(90deg, var(--sc) 24%, var(--tc) 43%, var(--tc) 63%, var(--tc) 80%, var(--sc) 100%);
  background-size: 200% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  outline-color: var(--sc);
  animation: highlight 2s linear infinite;
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translate(-50%);
}

.splashScreen-help {
  color: #e2b698;
  font-size: .7rem;
  position: absolute;
  bottom: .7em;
  left: .7em;
}

@media only screen and (max-width: 750px) {
  .splashScreen-help {
    bottom: .7em;
  }
}

.splashScreen-info a {
  color: var(--sc);
}

.profile-container {
  width: 20em;
  background-color: var(--sc);
  z-index: 1000;
  border-radius: .01em;
  margin: 1.4em;
  padding: .7em;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.profile-image-container {
  margin-top: 2.1em;
}

.profile-upload {
  font-size: inherit;
  margin-top: .7em;
  margin-bottom: 1.4em;
  display: inline-block;
}

.profile-input {
  font-size: inherit;
  width: 80%;
  background-color: #fff;
  border: none;
  border-radius: 0;
  margin-top: .35em;
  padding: .35em;
}

.profile-button {
  cursor: pointer;
  font-size: inherit;
  text-align: center;
  background: var(--sc);
  color: #fff;
  border: 1px solid #fff;
  border-radius: 0;
  margin-top: .7em;
  padding: .7em;
  display: inline-block;
}

.profile-button:hover {
  opacity: .5;
}

.profile-save {
  width: 5rem;
  float: right;
}

.profile-button-inactive {
  opacity: .5;
  pointer-events: none;
}

.profile-text {
  color: #fff;
  width: 100%;
  border-top: 1px solid #fff;
  padding-top: .466667em;
}

.profile-text-top {
  color: #fff;
  width: 80%;
  border-top: 1px solid #fff;
  padding-top: .466667em;
}

.nav-button {
  filter: drop-shadow(0 0 4px #fffc);
  color: #8b9cbd;
  border-radius: .02em;
  margin-top: .35em;
  margin-left: .35em;
  padding: .35em;
  font-size: 1.4rem;
  text-decoration: none;
  display: inline-block;
  box-shadow: inset 0 0 10px #ffffff80;
}

@media only screen and (max-width: 750px) {
  .nav-button {
    font-size: 1.1rem;
  }
}

.nav-button-closed {
  filter: drop-shadow(0 0 4px #fffc);
  color: #fff;
  background: #000 0 0 / 200% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: .5;
  border-radius: .02em;
  margin-top: .35em;
  margin-left: .35em;
  padding: .35em;
  font-size: 1.55rem;
  text-decoration: none;
  display: inline-block;
  box-shadow: inset 0 0 10px #ffffff80;
}

@media only screen and (max-width: 750px) {
  .nav-button-closed {
    font-size: 1.1rem;
  }
}

.nav-button-is-active {
  background: linear-gradient(90deg, var(--pc) 24%, var(--sc) 43%, var(--sc) 63%, var(--sc) 80%, var(--pc) 100%);
  background-size: 200% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  outline-color: var(--color);
  animation: highlight 9s linear infinite;
}

.nav-button-closed, .nav-button:hover {
  transition: transform .4s;
  transform: scale(.95);
}

.chat-content-message {
  margin-top: .1em;
  font-size: .9em;
}

.nav-is-live {
  width: .7em;
  height: .7em;
  border-radius: 3em;
  animation: blinks 5s linear infinite;
  display: block;
  position: absolute;
  top: 50%;
  left: 1em;
  transform: translate(-50%, -50%);
}

@keyframes blinks {
  0% {
    background-color: #fff;
  }

  25% {
    background-color: #f000;
  }

  50% {
    background-color: red;
  }

  70% {
    background-color: #f000;
  }

  100% {
    background-color: #fff;
  }
}

.support-button {
  z-index: 900;
  color: #fff;
  text-align: center;
  filter: drop-shadow(0 0 4px #000c);
  background: #571f91;
  border-radius: .175em;
  margin: .7em;
  padding: .7em .7em .35em;
  text-decoration: none;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: inset 0 0 10px #00000080;
}

.support-button img {
  width: 4em;
  margin-bottom: .35em;
  display: block;
}

.menu-button {
  z-index: 900;
  color: #000;
  text-align: center;
  width: 5em;
  height: 5em;
  cursor: pointer;
  background: #8b9cbd;
  background: var(--tc);
  color: var(--sc);
  border-radius: .005em;
  margin: .35em;
  padding: .35em .7em .7em;
  text-decoration: none;
  transition: all .5s;
  position: fixed;
  top: 0;
  left: 0;
}

.menu-button:hover {
  transition: all .5s;
  transform: scale(.9);
}

.overlay-button {
  z-index: 100;
  color: #000;
  text-align: left;
  width: 10em;
  filter: drop-shadow(0 0 4px #000c);
  cursor: pointer;
  background: #fff6;
  border-radius: .01em;
  margin: .35em;
  padding: .7em;
  line-height: 1;
  text-decoration: none;
  position: fixed;
  top: 9.8em;
  left: 0;
  box-shadow: inset 0 0 10px #00000080;
}

.overlay-button a {
  margin-bottom: .7em;
  display: block;
}

.menu-button-close {
  cursor: pointer;
  z-index: 950;
  color: #fff;
  text-align: center;
  background: none;
  border: 1px solid #fff;
  margin: .7em;
  padding: .7em .7em .35em;
  text-decoration: none;
  position: absolute;
  top: 0;
}

.menu-button-close img {
  width: 4em;
  margin-bottom: .35em;
  display: block;
}

.menu-button-close-color {
  cursor: pointer;
  z-index: 950;
  background: var(--background2);
  border: 1px solid var(--tc);
  text-align: center;
  margin: .7em;
  padding: .7em .7em .35em;
  text-decoration: none;
  position: absolute;
  top: 0;
}

.menu-button-close-color img {
  width: 4em;
  margin-bottom: .35em;
  display: block;
}

.menu-button-close:hover, .menu-button-close-color:hover {
  opacity: .5;
  transition: all .5s;
}

.menu-left {
  left: 0;
}

.menu-right {
  right: 0;
}

.about {
  height: 100%;
  background: var(--tc);
  z-index: 900;
  width: 50%;
  grid-template-columns: 1fr;
  column-gap: 1.4em;
  padding: 5.6em 1.4em 1.4em;
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  overflow: scroll;
  box-shadow: 0 8px 5px 5px #00000080;
}

.about p {
  margin-top: 0;
}

@media only screen and (max-width: 750px) {
  .about {
    width: 100%;
  }
}

.about-col {
  margin-bottom: 15em;
}

.about-col h1, .about-col div {
  margin-bottom: 1.4em;
}

.about-col a {
  color: inherit;
}

.logn-text p {
  margin-bottom: .7em;
}

.about-main-link {
  background: #8b9cbd;
  border-radius: .005em;
  margin-bottom: .7em;
  padding: .35em;
  text-decoration: none;
  display: inline-block;
  box-shadow: inset 0 0 10px #00000080;
  color: #000 !important;
}

.about-names {
  list-style-type: none;
}

.details {
  z-index: 10000;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  width: 100vw;
  height: 100vh;
  background: #001400e6;
  font-size: 1.25rem;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: inset 0 0 10px #00000080;
}

.container {
  width: 100vw;
  height: 100vh;
  padding: 2.8em;
  overflow: scroll;
}

.slick-slide img {
  width: 100%;
  max-height: calc(100vh - 12em);
  object-fit: contain;
  margin: auto;
  padding: 1.4em;
}

.gallery-captions {
  margin-top: 2.8em;
  padding-left: 1.4em;
}

.artwork-container-close {
  letter-spacing: .05em;
  text-align: right;
  z-index: 900;
  color: #fff;
  text-align: center;
  filter: drop-shadow(0 0 4px #000c);
  background: #5200bd;
  border-radius: .175em;
  margin: .35em;
  padding: .35em;
  font-size: 1.25rem;
  text-decoration: none;
  position: fixed;
  top: 0;
  right: 0;
  box-shadow: inset 0 0 10px #00000080;
}

.slick-dots li button {
  cursor: pointer;
  color: #0000;
  background: none;
  border: none;
  outline: none;
  padding: 5px;
  font-size: 2em;
  line-height: 0;
  display: block;
  width: 4em !important;
  height: 4em !important;
}

.slick-dots li button:before {
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: .25;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: slick;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 18px !important;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: #5200bd !important;
}

.text-captions {
  text-align: left;
  margin-top: 1.4em;
}

.livestream {
  width: 100vw;
  height: 100vh;
  z-index: 900;
  background: #000;
  padding: .7em 7em 7em;
  position: fixed;
  top: 0;
  left: 0;
  overflow: scroll;
  box-shadow: inset 0 0 25px #ffffff1a;
}

@media only screen and (max-width: 600px) {
  .livestream {
    padding: .7em;
  }
}

.livestream-overlay {
  width: 100vw;
  height: 100vh;
  z-index: 900;
  -webkit-backdrop-filter: blur(.8em);
  backdrop-filter: blur(.8em);
  background: #000000b3;
  padding: .7em 7em 7em;
  position: fixed;
  top: 0;
  left: 0;
  overflow: scroll;
  box-shadow: inset 0 0 25px #ffffff1a;
}

.livestream-title {
  margin-bottom: 1.4em;
  padding-left: 4.2em;
}

@media only screen and (max-width: 600px) {
  .livestream-title {
    margin-bottom: 5.6em;
    padding-left: 5.6em;
  }
}

.livestream-container {
  padding-top: 56.25%;
  position: relative;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.installationOverlay {
  z-index: 500;
  width: 60%;
  pointer-events: none;
  -webkit-backdrop-filter: blur(.5em);
  backdrop-filter: blur(.5em);
  background: #00000080;
  border-radius: .01em;
  padding: .7em;
  line-height: 1.36;
  position: fixed;
  bottom: 4.2em;
  left: .7em;
}

.language-switch {
  z-index: 900;
  color: #8b9cbd;
  text-align: left;
  width: 2.4em;
  cursor: pointer;
  text-align: center;
  background: #e2b698;
  border-radius: 100em;
  margin: .35em;
  padding: .56em;
  text-decoration: none;
  transition: all .5s;
  position: fixed;
  top: 5.6em;
  left: 0;
}

.language-switch:hover {
  transition: all .5s;
  transform: rotate(90deg);
}

.artwork-container {
  z-index: 1000;
  width: calc(100vw - 1.38em);
  height: calc(100vh - 1.38em);
  opacity: .5em;
  background: var(--background2);
  color: var(--tc);
  border: 1px solid var(--tc);
  position: fixed;
  top: .7em;
  left: .7em;
  overflow: auto;
}

.artwork-name, .artwork-title {
  margin-bottom: .35em;
  font-size: 2em;
  font-weight: normal;
  line-height: 1em;
}

.artwork-name {
  width: 70%;
  margin-bottom: .35em;
}

.artwork-year {
  margin-bottom: 2.1em;
  font-size: .75em;
}

.artwork-text {
  margin-bottom: 1.4em;
}

.artwork-bio {
  margin-bottom: 2.1em;
  font-size: .75em;
}

.artwork-link {
  grid-column: span 12;
  margin-bottom: 1.4em;
}

.artwork-link a {
  border-left: 1px solid var(--tc);
  border-bottom: 1px solid var(--tc);
  color: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 15em;
  margin-left: .15em;
  margin-right: .15em;
  padding: .854em;
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
}

@media only screen and (max-width: 1300px) {
  .artwork-link a {
    width: calc(100vw - 3.8em);
    margin-left: 3.8em;
  }
}

@media only screen and (max-width: 750px) {
  .artwork-link a {
    width: calc(100vw - 6.8em);
    margin-left: 3.8em;
    padding-right: 0;
  }
}

.artwork-link a:hover {
  background: linear-gradient(90deg, var(--dark) 24%, var(--sc) 43%, var(--sc) 63%, var(--sc) 80%, var(--dark) 100%);
  background-size: 200% 100%;
  animation: highlight 9s linear infinite;
}

.artwork-col-container {
  max-width: 60em;
  min-height: calc(100vh - 1.38em);
  border-left: 1px solid var(--tc);
  border-right: 1px solid var(--tc);
  gap: 1.4em;
  align-items: top;
  grid-template-columns: repeat(12, [col] 1fr);
  grid-auto-rows: min-content;
  margin: auto;
  padding: .7em;
  display: grid;
  overflow: auto;
}

.artwork-column {
  grid-column: span 6;
}

@media only screen and (max-width: 750px) {
  .artwork-column {
    grid-column: span 12;
    margin: 0;
  }
}

.artwork-image {
  width: 100%;
  padding-top: 0;
}

.artwork-player {
  width: 100%;
  margin-bottom: 1.4em;
  padding: 0 1.4em 1.4em .7em;
}

.chat-status {
  z-index: 1000;
  background: #00000080;
  border-radius: .01em;
  margin-top: .7em;
  padding: .35em .7em;
  font-size: 1.25rem;
  position: fixed;
  bottom: .35em;
  left: 4.9em;
}

.chat-status a {
  color: #fff;
  opacity: .5;
  cursor: pointer;
  margin-right: .7em;
  padding-bottom: .07em;
  text-decoration: none;
}

@media only screen and (max-width: 750px) {
  .chat-status {
    bottom: 2.52em;
    left: .35em;
  }
}

.chat-status-profile {
  display: inline-block;
}

.chat-status-profile img {
  width: 1.4em;
  height: 1.4em;
  vertical-align: middle;
  border-radius: 5em;
  margin-left: 1em;
}

.chat-title {
  z-index: 500;
  height: auto;
  min-width: 10em;
  vertical-align: top;
  max-width: 11em;
  border-radius: .0025em;
  margin-bottom: .175em;
  margin-left: .35em;
  padding: .35em;
  display: inline-block;
  overflow: hidden;
}

.easteregg-background {
  width: 100%;
  height: 100%;
  object-fit: fit;
  position: absolute;
  top: 0;
  left: 0;
}

.easteregg-container {
  z-index: 1000;
  width: calc(100vw - 1.38em);
  height: calc(100vh - 1.38em);
  opacity: .5em;
  color: var(--tc);
  background: #000c;
  position: fixed;
  overflow: auto;
}

.easteregg-text {
  width: 30%;
  min-height: 4rem;
  color: #000;
  padding: 1.4em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -33%);
}

.easteregg-link {
  color: inherit;
  color: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 8em;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  margin-top: 1.4em;
  margin-bottom: 1.4em;
  padding: .854em;
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
}

.easteregg-link:hover {
  background: linear-gradient(90deg, var(--dark) 24%, var(--sc) 43%, var(--sc) 63%, var(--sc) 80%, var(--dark) 100%);
  background-size: 200% 100%;
  animation: highlight 9s linear infinite;
}

.easteregg-title {
  font-size: inherit;
  font-size: 2em;
  font-weight: 500;
}

.easteregg-author {
  margin-top: 1.4em;
}

.liveVideo {
  width: 20vw;
  height: 15vw;
  box-sizing: border-box;
  z-index: 1000;
  position: absolute;
  bottom: 4.55em;
  left: .35em;
}

.liveVideo video {
  width: 100%;
  display: block;
  transform: scale(-1, 1);
  box-shadow: 0 0 15px 5px #000;
}

.lobby {
  text-align: left;
  font-size: 1.25em;
}

.participant {
  box-sizing: border-box;
  width: 20vw;
  height: 15vw;
  border-radius: .5em;
  justify-content: center;
  display: flex;
  position: relative;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
}

.identity {
  color: #fff;
  z-index: 10;
  padding-left: .35em;
  font-size: .7rem;
  position: absolute;
  bottom: .35em;
  left: 0;
}

#leaveRoom {
  margin-top: 25px;
  margin-left: 0;
}

.videomute, .videounmute {
  width: 7em;
  background: #ff0;
  border-radius: .5em;
  padding: .7em;
  position: absolute;
  top: .35em;
  left: .35em;
}

.videounmute {
  background: var(--warning);
}

#root {
  width: 100%;
  height: 100%;
}

.renderCanvas {
  width: 100%;
  height: 100%;
  touch-action: none;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
}

.main {
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.main-container, canvas {
  width: 100%;
  height: 100%;
}

.splashScreen-loading-pos {
  z-index: 100;
  width: 8vw;
  height: 8vw;
  position: absolute;
  top: calc(50% - 5vw);
  left: 0;
  transform: translate(0, -50%);
}

.splashScreen-contributors {
  z-index: 100;
  color: #fff;
  width: 100%;
  text-align: center;
  margin-top: 1.4em;
  font-size: 1.55rem;
  position: relative;
}

.artwork-videoplayer {
  width: 100%;
  height: 100%;
}

.artwork-videoplayer iframe {
  width: 100%;
  height: 100%;
  background: #000;
}

.video-container {
  width: 100%;
  background: #fff;
  border-radius: .01em;
  position: relative;
}

.video-container .video-container-content {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: .01em;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.video-container-after {
  content: "";
  width: 100%;
  height: 0;
  width: 100%;
  display: block;
}

.splashScreen-animation-enter {
  opacity: 0;
  transition: all 1s;
}

.splashScreen-animation-enter-active {
  opacity: 1;
  transition: all 1s;
}

.splashScreen-animation-exit {
  opacity: 1;
}

.splashScreen-animation-exit-active {
  opacity: 0;
  transition: opacity 1.8s;
}

.mt-1 {
  margin-top: 1.4em;
}

.artwork-caption {
  opacity: 1;
  margin-top: 2.8em;
}

canvas {
  z-index: 100;
}

textarea {
  font-size: inherit;
  resize: none;
  height: 1.4em;
  border: none;
}

.small {
  font-size: .75em;
}

.restartVideo {
  z-index: 1000;
  width: 7em;
  background: #ff0;
  border-radius: .5em;
  margin: .35em;
  padding: .7em;
  position: absolute;
  top: 15em;
  left: 0;
}

:root {
  --lh: 1.38rem;
  --pc: #ff5b00;
  --sc: #98a6c3;
  --tc: #7d8474;
  --warning: #ee4c30;
  --white: white;
  --dark: black;
  --darkhalf: #0000004d;
  --lightgray: #d3d3d3;
  --background: #e1dfc9;
  --background2: #171a22;
  --navcolor: #7d8474;
  --color2: #0f0;
  --color: black;
  --brightblue: #8b9cbd;
  --radius: .2em;
  --mini2: .6rem;
  --small2: .8rem;
}

.chat {
  z-index: 100;
  border-radius: var(--radius2);
  width: 15em;
  background-color: #e2b69833;
  padding: 0 .35em .35em;
  position: fixed;
  top: .35em;
  bottom: 3.26667em;
  right: .35em;
}

.chat-block {
  border-top: 1px solid #fff;
  padding: .35em;
}

.chat-block-admin {
  border-radius: .2em;
  background: #ff3300b7 !important;
}

.chat-block:nth-last-of-type(-n+3) {
  background: red;
}

.chat-block:nth-last-of-type(0) {
  background-color: #2f2121;
}

.chat-block:nth-last-of-type(1) {
  background-color: #2f2121e6;
}

.chat-block:nth-last-of-type(2) {
  background-color: #2f2121cc;
}

.chat-block:nth-last-of-type(3) {
  background-color: #2f2121b3;
}

.chat-block:nth-last-of-type(4) {
  background-color: #2f212199;
}

.chat-block:nth-last-of-type(5) {
  background-color: #2f212180;
}

.chat-block:nth-last-of-type(6) {
  background-color: #2f212166;
}

.chat-block:nth-last-of-type(7) {
  background-color: #2f21214d;
}

.chat-block:nth-last-of-type(8) {
  background-color: #2f212133;
}

.chat-block:nth-last-of-type(9) {
  background-color: #2f21211a;
}

.chat-block:nth-last-of-type(10) {
  background-color: #2f212100;
}

.chat-messages {
  height: calc(100% - 2em);
  width: 100%;
  word-break: break-all;
  overflow-y: auto;
}

.chat-image {
  width: 2.38em;
  height: 2.38em;
  float: left;
  filter: drop-shadow(0 0 4px #000c);
  border-radius: 100%;
  margin-top: 2px;
  margin-left: 7px;
  overflow: hidden;
}

.chat-image img {
  width: 100%;
  height: 100%;
}

.chat-content {
  max-width: 10em;
  height: 100%;
  margin-bottom: .35em;
  margin-left: 3.5em;
  padding: .233333em .35em .35em;
}

.chat-name {
  color: var(--sc);
  font-size: var(--mini2);
  margin-bottom: .25rem;
}

.chat-content-message {
  color: #fff;
  font-size: var(--small2);
  width: 10em;
  margin-top: .1em;
}

.chat-content-mymessage {
  color: var(--sc);
}

.chat-bar {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.chat-input {
  width: 100%;
  height: 2.5em;
  margin-top: .5rem;
}

.chat-input-form {
  border-radius: var(--radius);
  width: 100%;
}

.chat-input-field {
  width: 11rem;
  height: 2rem;
  background-color: var(--tc);
  color: #000;
  color: #fff;
  border: none;
  border-radius: 0;
  padding-left: .5em;
}

.chat-input-submit {
  cursor: pointer;
  float: right;
  width: 2em;
  height: 2em;
  font-size: inherit;
  background: var(--tc);
  border: none;
  border-radius: 100em;
}

.chat-input-submit:hover {
  opacity: .85;
}

.chat-bar-image {
  width: 2.52em;
  height: 2.52em;
}

/*# sourceMappingURL=index.e8d5a03f.css.map */
