@font-face {
    font-family: 'NH'; 
    src: url('../public/fonts/ABCDiatype-Medium.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}


$firsbrekpoint: 750px;

* {
    box-sizing: border-box;
    hyphens: none;
    // user-select: none;
    outline: none;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */ 
}


html, body {
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: yelow;
    font-family: 'NH','Helvetica Neue', arial, sans-serif;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    background: black;
    color: white;
    font-size: 19px;


    @media only screen and (max-width: $firsbrekpoint) {
        // font-size: 14px;    
    }
}