$pc: white;
$sc: #5200bd;
$tc: #8b9cbd;

// :root {
//     --lh: 1.38rem;
//     --pc: #005f51; 
//     --sc: #ff8900;
//     --tc: #ae8200;
//     --warning: #ee4c30;
//     --white: white;
//     --dark: black;
//     --darkhalf: rgba(0,0,0,0.3);
//     --lightgray: rgb(211, 211, 211);

//     --background: #E1DFC9;
//     --navcolor: #8000ff;

//     --color2: #00ff00;
//     --color: black;
// }
:root {
    --lh       : 1.38rem;
    --pc       : #ff5b00;
    // --sc       : rgb(82, 0, 189);
    --sc       : #98a6c3;
    // --tc       : #bf9c95;
    --tc       : #7d8474;
    --warning  : #ee4c30;
    --white    : white;
    --dark     : black;
    --darkhalf : rgba(0, 0, 0, 0.3);
    --lightgray: rgb(211, 211, 211);

    --background: #E1DFC9;
    --background2: #171a22;
    --navcolor  : #7d8474;

    --color2: #00ff00;
    --color : black;

    --brightblue: #8b9cbd;
    --radius    : 0.2em;
    --mini2     : 0.6rem;
    --small2    : 0.8rem
}



$radius: 0.01em;
$lh    : 1.4em;
$big   : 1.55rem;
$medium: 1.25rem;


$g1: linear-gradient(90deg, var(--sc) 0%, black 35%, var(--sc) 100%);