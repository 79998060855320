@use "sass:math";

.profile-container {
    margin: $lh;
    padding: $lh * 0.5;
    width: 20em;
    background-color: var(--sc);
    border-radius: $radius;
    position: fixed;
    left: 50%;
    top: 40%;
    transform: translate(-50%,-50%);
    z-index: 1000;
}

.profile-image-container {
    margin-top: $lh * 1.5;
}

.profile-upload {
    font-size: inherit;
    // background: $sc;
    // border: none;
    // border-radius: $lh / 8;
    // padding: $lh / 2;
    display: inline-block;
    margin-bottom: $lh;
    margin-top: $lh * 0.5;
}

.profile-input {
    font-size: inherit;
    border: none;
    border-radius: 0;
    background-color: white;
    margin-top: $lh * 0.25;
    width: 80%;
    padding: $lh * 0.25;
}
//#8b9cbd
.profile-button {
    cursor: pointer;
    font-size: inherit;
    text-align: center;
    background: var(--sc);
    
    border: 1px white solid;

    border-radius: 0;
    color: white;
    margin-top: $lh*0.5;
    padding: $lh * 0.5;

    display: inline-block;
}

.profile-button:hover{
    opacity: 0.5;
}

.profile-save {
    width: 5rem;
    float: right;
}

.profile-button-inactive {
    opacity: 0.5;
    pointer-events: none;
}

.profile-text{
    color: white;
    width: 100%;
    border-top: 1px white solid;
    padding-top: math.div($lh, 3);
}

.profile-text-top{
    color: white;
    width: 80%;
    border-top: 1px white solid;
    padding-top: math.div($lh, 3);
}