.artwork-container {
    z-index: 1000;
    position: fixed;
    top: $lh * 0.5;
    left: $lh * 0.5;
    width: calc(100vw - 1.38em);
    height: calc(100vh - 1.38em);
    // border-radius: $lh / 4;
    opacity: 0.5em;
    // padding: $lh;
    // padding-top: $lh / 2;
    background: var(--background2);
    color: var(--tc);
    border: 1px solid var(--tc);
    
    overflow: auto;
}


.artwork-name, .artwork-title {
    font-size: 2em;
    line-height: 1em;
    margin-bottom: $lh * 0.25;
    font-weight: normal;
}

.artwork-name{ 
    margin-bottom: $lh * 0.25;
    width: 70%;
}

.artwork-year {
    margin-bottom: $lh * 1.5;
    font-size: 0.75em;
}

.artwork-text {
    margin-bottom: $lh;
}

.artwork-text {
    margin-bottom: $lh;
}

.artwork-bio {
    margin-bottom: $lh * 1.5;
    font-size: 0.75em;
}

.artwork-link {
    // margin-top: $lh;
    grid-column: span 12;
    margin-bottom: $lh;
    // text-align: center;
    

    a {
        border-left: 1px solid var(--tc);
        border-bottom: 1px solid var(--tc);
        padding: $lh * 0.61;
        color: inherit;
        text-decoration: none;
        display: inline-block;
        // background: linear-gradient(90deg, var(--dark) 24%, var(--sc) 43%, var(--sc) 63%, var(--sc) 80%, var(--dark) 100%);
        // background-size: 200% 100%;
        // animation: highlight 9s linear infinite;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 15em;
        // width: calc(100vw - 3.8em); 
        margin-left: 0.15em;
        margin-right: 0.15em;

        @media only screen and (max-width: 1300px) {
           width: calc(100vw - 3.8em); 
           margin-left: 3.8em;   
        }

        @media only screen and (max-width: $firsbrekpoint) {
            margin-left: 3.8em;
            width: calc(100vw - 6.8em);
            padding-right: 0;
        }
        
    }

    a:hover {
        background: linear-gradient(90deg, var(--dark) 24%, var(--sc) 43%, var(--sc) 63%, var(--sc) 80%, var(--dark) 100%);
        background-size: 200% 100%;
        animation: highlight 9s linear infinite;
    }
}


.artwork-col-container {
    display: grid;
    // background: red;
    grid-template-columns: repeat(12, [col] 1fr);
    gap: $lh * 1;
    overflow: auto;
    margin: auto;
    // margin-top: $lh * 0.5;
    // margin-bottom: $lh;
    padding: $lh * 0.5;
    padding-top: $lh * 0.5;
    max-width: 60em;
    min-height: calc(100vh - 1.38em);
    border-left: 1px solid var(--tc);
    border-right: 1px solid var(--tc);
    align-items: top;
    grid-auto-rows: min-content;
}

.artwork-column {
    grid-column: span 6;
    // background: blue;

    // margin-left: 3.8em;

    @media only screen and (max-width: $firsbrekpoint) {
        grid-column: span 12;
        margin: 0;    
    }
}

.artwork-image {
    width: 100%;
    // padding: $lh;
    padding-top: 0;
    // padding-left: $lh * 0.5;
}

.artwork-player {
    width: 100%;
    padding: $lh;
    padding-top: 0;
    margin-bottom: $lh;
    padding-left: $lh * 0.5;
}