@use "sass:math";

.language-switch {
    z-index: 900;
    position: fixed;
    top: $lh * 4;
    left: 0;
    background: #e2b698;
    color: $tc;
    margin: $lh * 0.25;
    padding: math.div($lh, 2.5);
    padding-top: math.div($lh, 2.5);
    text-decoration: none;
    // border-radius: $radius / 2;
    border-radius: 100em;
    text-align: left;
    width: 2.4em;
    // height: 5em;
    // filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.8));
    // box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);
    cursor: pointer;
    text-align: center;
    transition: 500ms all;
}


.language-switch:hover {
    transform: rotate(90deg);
    transition: 500ms all;
}